const TWEEN = require('@tweenjs/tween.js');
import { standardCanvasHeight, standardCanvasWidth } from '../constants/commonData';
import { animateConstants, typeNames, TWEEN_START_VALUE, TWEEN_END_VALUE, MIN_TWEEN_DURATION } from '../constants/animation';
import { getRelativeLength, getLengthByOrigin, getchangedOriginProperties } from './tweenUtils';

/**
 * A transition item's definition.
 * @typedef {Object} TransitionItem
 * @property {string} name - the name of the transistion. Defined in {typeNames}.
 * @property {function} getTweens - a function that generates the Tweens for this transistion type.
 */
/**
 * The supported transitions for "start" animation. Used in animationMutations.startAnimation and AnimationPreview.vue
 * @type {TransitionItem[]}
 */
export const startTransitions = [
  {
    name: typeNames.fade,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () { Object.assign(target, { opacity: animateConstants.minOpacity }); })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ opacity: updatedTarget.opacity }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.slideLeft,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            left: canvas.width + getLengthByOrigin(updatedTarget.originX, updatedTarget.scaledWidth),
            opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline).to({ left: updatedTarget.left }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.slideRight,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            left: -updatedTarget.scaledWidth + getLengthByOrigin(updatedTarget.originX, updatedTarget.scaledWidth),
            opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay), new TWEEN.Tween(target, timeline).to({ left: updatedTarget.left }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.slideUp,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            top: canvas.height + getLengthByOrigin(updatedTarget.originY, updatedTarget.scaledHeight),
            opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline).to({ top: updatedTarget.top }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.slideDown,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            top: -updatedTarget.scaledHeight + getLengthByOrigin(updatedTarget.originY, updatedTarget.scaledHeight),
            opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline).to({ top: updatedTarget.top }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.fadeSlideLeft,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            left: updatedTarget.left +
              getRelativeLength(animateConstants.slideLength, standardCanvasWidth, canvas.width),
            opacity: animateConstants.minOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ left: updatedTarget.left, opacity: updatedTarget.opacity }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.fadeSlideRight,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            left: updatedTarget.left -
              getRelativeLength(animateConstants.slideLength, standardCanvasWidth, canvas.width),
            opacity: animateConstants.minOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ left: updatedTarget.left, opacity: updatedTarget.opacity }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.fadeSlideUp,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            top: updatedTarget.top +
              getRelativeLength(animateConstants.slideLength, standardCanvasHeight, canvas.height),
            opacity: animateConstants.minOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ top: updatedTarget.top, opacity: updatedTarget.opacity }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.fadeSlideDown,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            top: updatedTarget.top -
              getRelativeLength(animateConstants.slideLength, standardCanvasHeight, canvas.height),
            opacity: animateConstants.minOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline).to({ top: updatedTarget.top, opacity: updatedTarget.opacity }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.rotateIn,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
      var o = { value: TWEEN_START_VALUE };
      var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
      Object.assign(updatedTarget, originProps);
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            ...originProps,
            angle: updatedTarget.angle - animateConstants.rotateByDegrees, opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ angle: updatedTarget.angle }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.rotateOut,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
      var o = { value: TWEEN_START_VALUE };
      var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
      Object.assign(updatedTarget, originProps);
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            ...originProps,
            angle: updatedTarget.angle + animateConstants.rotateByDegrees, opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ angle: updatedTarget.angle }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.scaleUp,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
      var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
      Object.assign(updatedTarget, originProps);
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            ...originProps, scaleX: animateConstants.minScale,
            scaleY: animateConstants.minScale, opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.scaleDown,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
      var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
      Object.assign(updatedTarget, originProps);
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, {
            ...originProps, scaleX: updatedTarget.scaleX * animateConstants.maxScale,
            scaleY: updatedTarget.scaleY * animateConstants.maxScale, opacity: animateConstants.maxOpacity
          });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  },
  {
    name: typeNames.zoomIn,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
      var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
      Object.assign(updatedTarget, originProps);
      var o = { value: TWEEN_START_VALUE };
      return [new TWEEN.Tween(o, timeline)
        .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
        .onStart(function () {
          Object.assign(target, { ...originProps, opacity: animateConstants.maxOpacity });
        })
        .delay(startDelay),
      new TWEEN.Tween(target, timeline)
        .to({ scaleX: updatedTarget.scaleX * animateConstants.maxScale, scaleY: updatedTarget.scaleY * animateConstants.maxScale }, duration)
        .easing(TWEEN.Easing[easing.ease][easing.type])];
    }
  }
];
