const TWEEN = require('@tweenjs/tween.js');
import { standardCanvasWidth } from '../constants/commonData';
import { animateConstants } from '../constants/animation';
import { getRelativeLength, getchangedOriginProperties } from './tweenUtils';

/**
 * The supported transitions for "middle" animation
 * @constant
 * @type {TransitionItem[]}
 * @default
 */
export const middleTransitions = {
  Blink: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
    var opacity = 1;
    var backUp = updatedTarget.opacity;
    var blinkduration = animateConstants.middleAnimateDuration;
    var remainder = duration % blinkduration;
    var blinkCount = Math.ceil(duration / blinkduration);
    var lastTween;
    var opacityValues = [];
    for (var i = 0; i < blinkCount; i++) {
      if (i == (blinkCount - 1)) {
        opacity = backUp;
        if (remainder != 0) {
          lastTween = new TWEEN.Tween(target, timeline).to(
            {
              opacity: [0.2, opacity]
            }, remainder);
          break;
        }
      }
      opacityValues.push(0.2); opacityValues.push(opacity);
    }
    var tweens = [new TWEEN.Tween(target, timeline).to({
      opacity: opacityValues
    }, duration - remainder)
      .delay(startDelay)];
    if (lastTween)
      tweens.push(lastTween);
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Move: (target, timeline, updatedTarget, duration, startDelay, easing, canvas) => {
    var leftValues = [];
    var remainder = duration % animateConstants.moveDuration;
    var count = Math.ceil(duration / animateConstants.moveDuration);
    var length = getRelativeLength(animateConstants.moveLength, standardCanvasWidth, canvas.width);
    var moveLeft = (updatedTarget.left - length).toFixed(10) * 1;
    var moveRight = (updatedTarget.left + length).toFixed(10) * 1;
    var lastTween, left = moveLeft;
    for (var i = 0; i < count; i++) {
      left = (left == moveLeft) ? moveRight : moveLeft;
      if (i == (count - 1)) {
        left = updatedTarget.left;
        if (remainder != 0) {
          lastTween = new TWEEN.Tween(target, timeline).to(
            {
              left: [left]
            }, remainder);
          break;
        }
      }
      leftValues.push(left);
    }
    var tweens = [new TWEEN.Tween(target, timeline).to({
      left: leftValues
    }, duration - remainder)
      .delay(startDelay)];
    if (lastTween)
      tweens.push(lastTween);
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Spin: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
    var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
    Object.assign(updatedTarget, originProps);
    // change fabric object origin to center before middle animation starts
    var tweens = [new TWEEN.Tween(target, timeline).to(originProps, 0)
      .delay(startDelay)];
    var remainder = duration % animateConstants.middleAnimateDuration;
    var spinCount = Math.ceil(duration / animateConstants.middleAnimateDuration);
    var lastTween, angle = updatedTarget.angle;
    var spinValues = [];
    for (var i = 0; i < spinCount; i++) {
      if (i == (spinCount - 1) && remainder != 0) {
        lastTween = new TWEEN.Tween(target, timeline).to({ angle: angle + (i + 1) * animateConstants.spinDeg }, remainder);
        break;
      }
      spinValues.push(angle + (i + 1) * animateConstants.spinDeg);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({
      angle: spinValues
    }, duration - remainder));
    if (lastTween)
      tweens.push(lastTween);
    tweens.push(new TWEEN.Tween(target, timeline).to({ angle }, 0));
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  HeartBeat: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
    var backUp = { scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY };
    var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
    Object.assign(updatedTarget, originProps);
    // change fabric object origin to center before middle animation starts
    var tweens = [new TWEEN.Tween(target, timeline).to(originProps, 0)
      .delay(startDelay)];
    var heartBeatDuration = 800;
    var remainder = duration % heartBeatDuration;
    var heartBeatCount = Math.ceil(duration / heartBeatDuration);
    var scaleX = updatedTarget.scaleX / 100 * 85;
    var scaleY = updatedTarget.scaleY / 100 * 85;
    for (var i = 0; i < heartBeatCount; i++) {
      if (i == (heartBeatCount - 1) && remainder != 0)
        heartBeatDuration = remainder;
      tweens.push(new TWEEN.Tween(target, timeline).to({
        scaleX: [scaleX, updatedTarget.scaleX, scaleX, updatedTarget.scaleX],
        scaleY: [scaleY, backUp.scaleY, scaleY, backUp.scaleY]
      }, heartBeatDuration / 2)
        .delay(i == 0 ? i : heartBeatDuration / 2));
    }
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Pulsate: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
    var backUp = { scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY };
    var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
    var tweens = [new TWEEN.Tween(target, timeline).to(originProps, 0)
      .delay(startDelay)];
    Object.assign(updatedTarget, originProps);
    // change fabric object origin to center before middle animation starts
    var pulsateDuration = 400;
    var remainder = duration % pulsateDuration;
    var pulsateCount = Math.ceil(duration / pulsateDuration);
    var scaleXValues = [];
    var scaleYValues = [];
    var scaleX = backUp.scaleX / 100 * 125;
    var scaleY = backUp.scaleY / 100 * 125;
    var lastTween;
    for (var i = 0; i < pulsateCount; i++) {
      if (i == (pulsateCount - 1) && remainder != 0) {
        lastTween = new TWEEN.Tween(target, timeline).to(
          {
            scaleX: [scaleX, backUp.scaleX],
            scaleY: [scaleY, backUp.scaleY]
          }, remainder);
        break;
      }
      scaleXValues.push(scaleX); scaleXValues.push(backUp.scaleX);
      scaleYValues.push(scaleY); scaleYValues.push(backUp.scaleY);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({
      scaleX: scaleXValues,
      scaleY: scaleYValues
    }, duration - remainder));
    if (lastTween)
      tweens.push(lastTween);
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Ping: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, hasOriginChanged) => {
    var originProps = getchangedOriginProperties(updatedTarget, 'center', hasOriginChanged);
    Object.assign(updatedTarget, originProps);
    var backUp = { opacity: updatedTarget.opacity, scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY };
    var tweens = [new TWEEN.Tween(target, timeline).to(originProps, 0)
      .delay(startDelay)];
    var style = { opacity: 0.3, scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY };
    var remainder = duration % animateConstants.middleAnimateDuration;
    var pingCount = Math.ceil(duration / animateConstants.middleAnimateDuration);
    var scaleXValues = [];
    var scaleYValues = [];
    var opacityValues = [];
    var lastTween;
    for (var i = 0; i < pingCount; i++) {
      if (i == (pingCount - 1)) {
        style = backUp;
        if (remainder != 0) {
          lastTween = new TWEEN.Tween(target, timeline).to(
            {
              opacity: [animateConstants.minOpacity, style.opacity],
              scaleX: [0, style.scaleX],
              scaleY: [0, style.scaleY]
            }, remainder);
          break;
        }
      }
      opacityValues.push(animateConstants.minOpacity); scaleXValues.push(0); scaleYValues.push(0);
      opacityValues.push(style.opacity); scaleXValues.push(style.scaleX); scaleYValues.push(style.scaleY);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({ opacity: opacityValues, scaleX: scaleXValues, scaleY: scaleYValues }, duration - remainder));
    if (lastTween)
      tweens.push(lastTween);
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  }
};
